/**
 * @name transposeTableArray
 */
type TableArray<T> = T[][];

export const transposeTableArray = <T>(table: TableArray<T>): TableArray<T> => {
  const newTable: TableArray<T> = [];

  for (let y = 0; y < table.length; y += 1) {
    const tableRow = table[y];

    for (let x = 0; x < tableRow.length; x += 1) {
      if (!newTable[x]) {
        newTable[x] = [];
      }
      newTable[x].push(tableRow[x]);
    }
  }

  return newTable;
};
