<template>
  <tml-new-popup
    :name="`size-popup-${product.id}`"
    title="Sizing"
    layout="standard"
    size="normal"
    :fill="false"
    @enter="onOpen"
  >
    <template #content>
      <tml-loader :loading="loading" height="400px">
        <template v-if="sizeChart">
          <tml-tabbed-container v-if="sizeChart.sizes">
            <tml-tab-header>
              <tml-tab-header-item
                v-for="(chart, unit) in sizeChart.sizes"
                :key="unit"
              >
                {{ unit }}
              </tml-tab-header-item>
            </tml-tab-header>
            <tml-tab-body>
              <tml-tab-body-item
                v-for="(chart, unit) in sizeChart.sizes"
                :key="unit"
              >
                <size-chart-table
                  v-if="maxDevice('md')"
                  :table-data="transposeTableArray(chart)"
                />
                <size-chart-table v-else :table-data="chart" />
              </tml-tab-body-item>
            </tml-tab-body>
          </tml-tabbed-container>
          <template v-if="sizeChart.instructions">
            <p
              v-for="(instruction, name) in sizeChart.instructions"
              :key="name"
              class="mb-2"
            >
              <span v-if="name !== 'tagLine'"><strong>{{ startCase(name) }}</strong>: {{ instruction }}</span>
              <span v-else>{{ instruction }}</span>
            </p>
          </template>
        </template>

        <div v-if="showReturnsInfo">
          <p class="mt-8 mb-4">
            There's no need to worry because we guarantee
            <tml-anchor
              :href="`/${helpPage}/?search=Returns`"
              :text="`simple returns & exchanges${
                isEU.passes || isRestOfWorld.passes
                  ? ''
                  : ', with freepost returns for all UK orders'
              }`"
            />.
          </p>
        </div>

        <p class="mb-4">
          Every one of our customers is important to us. So you're in safe hands
          - We'll make sure it's quick and easy to get your size right, no
          hassle guaranteed.
        </p>
        <p class="mb-4">
          Fast answers can be found in our
          <tml-anchor :href="`/${helpPage}/`" text="FAQ" />
        </p>
      </tml-loader>
    </template>
  </tml-new-popup>
</template>

<script>
import {formatUrl, maxDevice} from '@teemill/common/helpers';
import {startCase} from '@teemill/utilities';
import {isEU, isRestOfWorld} from '@teemill/modules/store-front';
import {splitTests} from '@teemill/common/classes';
import SizeChartTable from './SizeChartTable.vue';

import {transposeTableArray} from '@teemill/utilities';

export default {
  name: 'SizeChartPopup',

  components: {
    SizeChartTable,
  },

  props: {
    product: Object,
    showReturnsInfo: {
      type: Boolean,
      default: true,
    },
    helpPage: {
      type: String,
      default: 'help',
    },
  },

  data() {
    return {
      loading: true,

      showCentimeters: true,
      sizeChart: null,

      transposeTableArray,
      isEU,
      isRestOfWorld,
      splitTests,
    };
  },

  methods: {
    startCase,

    maxDevice,

    onOpen() {
      if (this.sizeChart) {
        return;
      }

      this.axios
        .get(formatUrl(`/omnis/v3/size-charts/get/${this.product.sizeChartId}`))
        .success(data => {
          this.sizeChart = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
