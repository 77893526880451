<template>
  <div class="size-chart-table">
    <tml-data-table
      v-if="tableData"
      :table-data="tableData.slice(1, tableData.length)"
    >
      <template #table-head>
        <tml-data-table-head-cell
          v-for="(head, index) in tableData[0]"
          :key="index"
        >
          {{ head }}
        </tml-data-table-head-cell>
      </template>
      <template #table-body="row">
        <tml-data-table-body-cell
          v-for="(data, index) in row.data"
          :key="index"
        >
          {{ data }}
        </tml-data-table-body-cell>
      </template>
    </tml-data-table>
  </div>
</template>

<script>
export default {
  name: 'SizeChartTable',
  props: {
    tableData: Array,
  },
};
</script>
